@mixin bulk-order-status-theme($theme) {
  $is-dark: map-get($theme, is-dark);

  bulk-processing {
    .title {
      @if ($is-dark) {
        color: white;
      } @else {
        color: #757575;
      }

      .circular-inside,
      .comments {
        @if ($is-dark) {
          background-color: #424242;
          color: white;
        } @else {
          background-color: #fafafa;
          color: rgba(0, 0, 0, 0.54);
        }
      }
    }
  }
}
