// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

//import mat-icons
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

// Added Classes
.text-boxed-sm {
  border-radius: 2px;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;

  padding: 2px 4px !important;
  margin: 0;
}

// .text-boxed-lg {
//   border-radius: 2px;
//   font-size: 14px;
//   font-weight: 600;
//   white-space: nowrap;

//   padding: 4px 8px !important;
//   margin: 0 !important;
// }

.text-captialized {
  text-transform: capitalize;
}

// for orders table > td : height
.custom-td {
  height: auto !important;
  min-height: 58px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
}

// mat-tooltip
.mat-tooltip {
  white-space: pre-wrap;
  border-left: 6px solid #00bcd4;
  font-size: 13px;
}

.font-size-15 {
  font-size: 15px !important;
}

.br-1-grey {
  border-right: 1px solid #e0e0e0;
}

/**
* Hide increment and decrement button in Chrome and Safari.
*/

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**
* Hide increment and decrement button in Firefox.
*/

input[type="number"] {
  -moz-appearance: textfield;
}

// give height to input type color
input[type="color"] {
  height: 20px;
}

// to add border to message box
.br-4 {
  border-radius: 4px !important;
}

.d-none {
  display: none;
}

// to add line-break in mat snackbar
.mat-snackbar {
  white-space: pre-wrap;
}

.d-flex {
  display: flex;
}

.fd-col {
  flex-direction: column;
}

.red-text {
  color: #f44336;
}

.green-text {
  color: #4caf50;
}

.custom_couriers {
  margin-left: 5px;
  padding: 2px 6px;
  font-size: 10px;
  border: 1px solid #00bcd4;
  border-radius: 10px;
  color: #00bcd4;
}
