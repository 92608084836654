@mixin view-preference-theme($theme) {
  $is-dark: map-get($theme, is-dark);

  view-preference {
    #icon-color {
      @if ($is-dark) {
        // background: #424242;

        color: rgba(255, 255, 255, 0.7);
      } @else {
        // background: white;
        color: rgba(22, 22, 22, 0.87);
      }
    }
  }
}
