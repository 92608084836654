@mixin company-details-theme($theme) {
  $is-dark: map-get($theme, is-dark);

  app-company-details {
    .accordian {
      @if ($is-dark) {
        background-color: #424242;
      } @else {
        background-color: #f5f5f5;
      }
    }
  }
}
