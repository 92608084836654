@mixin toolbar-heading-theme($theme) {
  $is-dark: map-get($theme, is-dark);

  toolbar-heading {
    #toolbar-background {
      @if ($is-dark) {
        background: #424242;
      } @else {
        background: white;
      }
    }
  }
}
