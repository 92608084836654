@mixin ndr-reattempt-theme($theme) {
  $is-dark: map-get($theme, is-dark);

  app-ndr-reattempt-delivery {
    .file-note {
      @if ($is-dark) {
        background: #232323;
      }
    }
  }
}
