@mixin rate-calculator-theme($theme) {
  $is-dark: map-get($theme, is-dark);
  rate-calculator-form {
    .page-layout {
      .btn-increment-decrement {
        @if ($is-dark) {
          color: #e0e0e0;
        } @else {
          color: black;
        }
      }
    }
  }
}
