@mixin input-field-theme($theme) {
  $is-dark: map-get($theme, is-dark);

  app-input-field,
  app-select-field,
  app-box-data,
  app-date-field,
  app-warehouse-list {
    #table-body-cell {
      .form-control {
        @if ($is-dark) {
          color: white;
        } @else {
          color: black;
        }
      }

      .form-control:disabled,
      .form-control[readonly] {
        @if ($is-dark) {
          background-color: #2c2c2c;
        } @else {
          background-color: #e9ecef;
        }
      }
    }
  }

  app-box-data,
  app-select-field,
  app-warehouse-list {
    #option {
      @if ($is-dark) {
        background-color: #424242;
      } @else {
        background-color: white;
      }
    }
  }
}
