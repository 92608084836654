@mixin settings-theme($theme) {
  $is-dark: map-get($theme, is-dark);
  @if ($is-dark) {
    app-settings {
      .content-card,
      .content,
      .toolbar,
      toolbar-heading #toolbar-background {
        background-color: #303030;
      }
    }
  }
}
