@mixin image-input-theme($theme) {
  $is-dark: map-get($theme, is-dark);

  sg-image-input {
    .label {
      @if ($is-dark) {
        background-color: #424242;
        color: rgb(184, 178, 178);
      } @else {
        background-color: white;
        color: rgba(27, 27, 27, 0.6);
      }

      .container {
        @if ($is-dark) {
          border: 1.3px solid rgba(155, 151, 151, 0.699);
        } @else {
          border: 1.3px solid #0000001f;
        }
      }
    }
  }
}
