@mixin upload-bulk-theme($theme) {
  $is-dark: map-get($theme, is-dark);

  app-upload-bulk-weight-discripancy {
    .note {
      @if ($is-dark) {
        color: #e0e0e0;
      } @else {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
