@mixin large-preview-form-theme($theme) {
  $is-dark: map-get($theme, is-dark);

  large-preview-form {
    #header {
      @if ($is-dark) {
        background: #424242;
      } @else {
        background: white;
      }
    }
  }
}
