@mixin material-table-theme($theme) {
  $is-dark: map-get($theme, is-dark);
  @if ($is-dark) {
    material-table {
      .unread {
        background-color: #b8bebe !important;
        color: black;
      }
    }
  }
}
