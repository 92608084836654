@use '@angular/material' as mat;
@mixin mail-confirm-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  app-zoho-inventory,
  app-magento1-oauth,
  app-instamojo {
    #mail-confirm {
      #mail-confirm-form-wrapper {
        #mail-confirm-form {
          @if ($is-dark) {
            background: mat.get-color-from-palette($fuse-navy, 600);
          } @else {
            background: map-get($background, card);
          }

          .subtitle {
            color: map-get($foreground, secondary-text);
          }
        }
      }
    }
  }
}
