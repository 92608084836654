@import "src/@fuse/scss/fuse";

// For setting logistics backgroung to emiza tenant app

@mixin set-background() {
  background: url("/assets/images/backgrounds/logistics-bg.jpg") !important;
  background-size: cover !important;
  box-shadow: inset 2000px 0 0 0 rgba(255, 255, 255, 0.1) !important;
}

@mixin set-intro-logo-position() {
  transform: translate(-5px, -10px) !important;
}

login {
  #login {
    @include set-background();

    #login-intro {
      padding: 90px !important;

      @include media-breakpoint("sm") {
        padding: 70px !important;
      }

      .logo {
        @include set-intro-logo-position();
      }
    }
  }
}

register {
  #register {
    @include set-background();

    #register-intro {
      padding: 90px !important;

      @include media-breakpoint("sm") {
        padding: 70px !important;
      }

      .logo {
        @include set-intro-logo-position();
      }
    }
  }
}

app-zoho-inventory,
app-magento1-oauth,
app-instamojo {
  #mail-confirm {
    @include set-background();
  }
}

instamojo-onboarding {
  #register {
    @include set-background();
  }
}

gmail-login {
  #register {
    @include set-background();
  }
}

forgot-password {
  #forgot-password {
    @include set-background();
  }
}

otp-verify {
  #reset-password {
    @include set-background();
  }
}

reset-password {
  #reset-password {
    @include set-background();
  }
}
