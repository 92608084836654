@mixin courier-rates-theme($theme) {
  $is-dark: map-get($theme, is-dark);
  @if ($is-dark) {
    app-edit-rates {
      .header-definition,
      .container {
        background-color: #303030 !important;
      }

      .form-control {
        input {
          background-color: transparent;
          color: #fff !important;

          &.ng-invalid {
            background-color: rgb(248, 145, 145);
          }
        }
      }
    }
  }
}
