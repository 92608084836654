@mixin rates-theme($theme) {
  $is-dark: map-get($theme, is-dark);
  @if ($is-dark) {
    app-rate-list {
      .header-definition,
      .container {
        background-color: #303030 !important;
      }

      // .rto > td {
      //   background-color: darken(#d6d8db, 20%) !important;
      // }

      // .reverse > td {
      //   background-color: darken(#bee5eb, 20%) !important;
      // }

      // .reverseQc > td {
      //   background-color: darken(#c3e6cb, 20%) !important;
      // }
    }
  }
}
