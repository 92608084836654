@mixin sku-theme($theme) {
  $is-dark: map-get($theme, is-dark);
  app-sku {
    mat-header-cell {
      @if ($is-dark) {
        background-color: #303030;
      } @else {
        color: #f5f5f5;
      }
    }
  }
}
